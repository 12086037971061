@tailwind base;

@tailwind components;

@tailwind utilities;

.get-out-r {
    animation: translateOut ease 0.6s;
    visibility: hidden;
    -webkit-animation: translateOut ease 0.6s;
    -moz-animation: translateOut ease 0.6s;
    -o-animation: translateOut ease 0.6s;
    -ms-animation: translateOut ease 0.6s;
}

@keyframes translateOut {
    100% {
        right: -20%;
        opacity: 0.5;
    }

    0% {
        right: 4%;
        opacity: 1;
        visibility: visible;
    }
}

@-moz-keyframes translateOut {
    100% {
        right: -20%;
        opacity: 0.5;
    }

    0% {
        right: 4%;
        opacity: 1;
        visibility: visible;
    }
}

@-webkit-keyframes translateOut {
    100% {
        right: -20%;
        opacity: 0.5;
    }

    0% {
        right: 4%;
        opacity: 1;
        visibility: visible;
    }
}

@-o-keyframes translateOut {
    100% {
        right: -20%;
        opacity: 0.5;
    }

    0% {
        right: 4%;
        opacity: 1;
        visibility: visible;
    }
}

@-ms-keyframes translateOut {
    100% {
        right: -20%;
        opacity: 0.5;
    }

    0% {
        right: 4%;
        opacity: 1;
        visibility: visible;
    }
}

.zoom-in {
    animation: zoom ease 0.6s;
    position: relative;
    -webkit-animation: zoom ease 0.6s;
    -moz-animation: zoom ease 0.6s;
    -o-animation: zoom ease 0.6s;
    -ms-animation: zoom ease 0.6s;
}

@keyframes zoom {
    0% {
        width: 25%;
        opacity: 0.5;
    }

    100% {
        width: 75%;
        opacity: 1;
    }
}

@-moz-keyframes zoom {
    0% {
        width: 25%;
        opacity: 0.5;
    }

    100% {
        width: 75%;
        opacity: 1;
    }
}

@-webkit-keyframes zoom {
    0% {
        width: 25%;
        opacity: 0.5;
    }

    100% {
        width: 75%;
        opacity: 1;
    }
}

@-o-keyframes zoom {
    0% {
        width: 25%;
        opacity: 0.5;
    }

    100% {
        width: 75%;
        opacity: 1;
    }
}

@-ms-keyframes zoom {
    0% {
        width: 25%;
        opacity: 0.5;
    }

    100% {
        width: 75%;
        opacity: 1;
    }
}

.entering-t {
    animation: translate-down ease 0.6s;
    -webkit-animation: translate-down ease 0.6s;
    -moz-animation: translate-down ease 0.6s;
    -o-animation: translate-down ease 0.6s;
    -ms-animation: translate-down ease 0.6s;
}

@keyframes translate-down {
    0% {
        top: -120px;
        opacity: 0.5;
    }

    100% {
        top: 0px;
        opacity: 1;
    }
}

@-moz-keyframes translate-down {
    0% {
        top: -120px;
        opacity: 0.5;
    }

    100% {
        top: 0px;
        opacity: 1;
    }
}

@-webkit-keyframes translate-down {
    0% {
        top: -120px;
        opacity: 0.5;
    }

    100% {
        top: 0px;
        opacity: 1;
    }
}

@-o-keyframes translate-down {
    0% {
        top: -120px;
        opacity: 0.5;
    }

    100% {
        top: 0px;
        opacity: 1;
    }
}

@-ms-keyframes translate-down {
    0% {
        top: -120px;
        opacity: 0.5;
    }

    100% {
        top: 0px;
        opacity: 1;
    }
}

.entering-b {
    animation: translate-down ease 0.6s;
    -webkit-animation: translate-down ease 0.6s;
    -moz-animation: translate-down ease 0.6s;
    -o-animation: translate-down ease 0.6s;
    -ms-animation: translate-down ease 0.6s;
}

@keyframes translate-down {
    0% {
        bottom: -120px;
        opacity: 0.5;
    }

    100% {
        bottom: 0px;
        opacity: 1;
    }
}

@-moz-keyframes translate-down {
    0% {
        bottom: -120px;
        opacity: 0.5;
    }

    100% {
        bottom: 0px;
        opacity: 1;
    }
}

@-webkit-keyframes translate-down {
    0% {
        bottom: -120px;
        opacity: 0.5;
    }

    100% {
        bottom: 0px;
        opacity: 1;
    }
}

@-o-keyframes translate-down {
    0% {
        bottom: -120px;
        opacity: 0.5;
    }

    100% {
        bottom: 0px;
        opacity: 1;
    }
}

@-ms-keyframes translate-down {
    0% {
        bottom: -120px;
        opacity: 0.5;
    }

    100% {
        bottom: 0px;
        opacity: 1;
    }
}


.entering-r {
    animation: translate ease 0.4s;
    -webkit-animation: translate ease 0.4s;
    -moz-animation: translate ease 0.4s;
    -o-animation: translate ease 0.4s;
    -ms-animation: translate ease 0.4s;
}

@keyframes translate {
    0% {
        right: -20%;
        opacity: 0.5;
    }

    100% {
        right: 4%;
        opacity: 1;
    }
}

@-moz-keyframes translate {
    0% {
        right: -20%;
        opacity: 0.5;
    }

    100% {
        right: 4%;
        opacity: 1;
    }
}

@-webkit-keyframes translate {
    0% {
        right: -20%;
        opacity: 0.5;
    }

    100% {
        right: 4%;
        opacity: 1;
    }
}

@-o-keyframes translate {
    0% {
        right: -20%;
        opacity: 0.5;
    }

    100% {
        right: 4%;
        opacity: 1;
    }
}

@-ms-keyframes translate {
    0% {
        right: -20%;
        opacity: 0.5;
    }

    100% {
        right: 4%;
        opacity: 1;
    }
}

.fade-out {
    animation: fadeOut ease 1s;
    opacity: 0;
    -webkit-animation: fadeOut ease 1s;
    -moz-animation: fadeOut ease 1s;
    -o-animation: fadeOut ease 1s;
    -ms-animation: fadeOut ease 1s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade-in {
    animation: fadeIn ease 0.6s;
    opacity: 1;
    -webkit-animation: fadeIn ease 0.6s;
    -moz-animation: fadeIn ease 0.6s;
    -o-animation: fadeIn ease 0.6s;
    -ms-animation: fadeIn ease 0.6s;
}

@keyframes fadeIn {
    100% {
        opacity: 1;
    }

    0% {
        opacity: 0;
    }
}

@-moz-keyframes fadeIn {
    100% {
        opacity: 1;
    }

    0% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeIn {
    100% {
        opacity: 1;
    }

    0% {
        opacity: 0;
    }
}

@-o-keyframes fadeIn {
    100% {
        opacity: 1;
    }

    0% {
        opacity: 0;
    }
}

@-ms-keyframes fadeIn {
    100% {
        opacity: 1;
    }

    0% {
        opacity: 0;
    }
}

@supports (-webkit-touch-callout: none) {

    /* CSS specific to iOS devices */
    .iphone-vertical {
        margin-top: 4rem;
        margin-bottom: 5rem;
    }

    .iphone-bottom {
        position: absolute;
        bottom: 0;
    }

    .iphone-mb-16 {
        margin-bottom: 8rem;
    }
}

.appearing {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s 1s, opacity 0s 1s;
}

.text-vertical-rotated {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
}

.absolute-tr {
    position: absolute;
    top: 1%;
    right: 3%;
}

.absolute-tl {
    position: absolute;
    top: 6%;
    left: 2%;
}

.firebase-emulator-warning {
    display: none;
}

.text-vertical {
    writing-mode: vertical-lr;
    text-orientation: upright;
}

.text-invisible {
    color: rgba(255, 255, 255, 0);
}

.disappearing {
    pointer-events: none;
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 0s 1s linear;
}

.settings-page-arrows {
    position: absolute;
    top: 40%;
    background-color: transparent;
}

.sm\:ok-position1 {
    position: absolute;
    top: 45%;
}

.sm\:ok-position2 {
    position: absolute;
    top: 31%;
    right: 10%;
}

.bottom-right-custom {
    position: fixed;
    bottom: 9rem;
    right: 4%;
  }
  
  .bottom-right-custom2 {
    position: fixed;
    bottom: 4rem;
    right: 4%;
  }

  .bottom-right-custom3 {
    position: fixed;
    bottom: 14rem;
    right: 4%;
  }

.bottom-center-custom {
    position: fixed;
    bottom: 45px;
}

.modal-positon {
    position: absolute;
    bottom: 8vh;
}

.curve {
    height: 225px;
    width: 100%;
}

.curve::before {
    content: '';
    display: block;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    background-color: red;
    transform: translate(85%, 60%);
}

.curve::after {
    content: '';
    display: block;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    background-color: yellow;
    transform: translate(-4%, 40%);
    z-index: -1;
}

.mainColor {
    filter: invert(57%);
    filter: sepia(30%);
    filter: saturate(4794%);
    filter: hue-rotate(1deg); 
    filter: brightness(107%);
    filter: contrast(103%);
}

.pt-18 {
    padding-top: 4.7rem;
}

.backdrop-blur {
    backdrop-filter: blur(4px);
}

.top-50 {
    position: absolute;
    top: 50%;
}

.top-25 {
    position: absolute;
    top: 25%;
}

.right-5 {
    position: absolute;
    right: 5%;
}

.right-6 {
    position: absolute;
    right: 6%;
}

.right-10 {
    position: absolute;
    right: 10%;
}

.bottom-2 {
    position: absolute;
    bottom: 2%;
}

.bottom-5 {
    position: absolute;
    bottom: 5%;
}

.left-5 {
    position: absolute;
    left: 5%;
}

.top-6 {
    position: absolute;
    top: 6%;
}

.top-10 {
    position: absolute;
    top: 10%;
}

.badge {
    position: absolute;
    top:-14px;
    right:-14px;
  }

.relative-top-0{
    position: relative;
    top: 0%;
  }
  
  .relative-right-0{
    position: relative;
    right: 0%;
  }

.h-38 {
    height: 8.5rem;
}

.h-28 {
    height: 7rem;
}

.h-70 {
    height: 17rem;
}

.h-82 {
    height: 18rem;
}

.h-100 {
    height: 24rem;
}

.h-140 {
    height: 28rem;
}

.h-200 {
    height: 40rem;
}

.text-10xl {
    font-size: 10rem;
}

.xl\:text-10xl {
    font-size: 10rem;
}

.shadow-custom {
    box-shadow: -10px 15px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.postIt-shadow {
    box-shadow: -10px 15px 12px 2px rgba(100, 100, 100, 0.5),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

.text-customsize {
    font-size: 1.75rem;
}

.sm\:text-customsize {
    font-size: 1.75rem;
}

.md\:text-customsize {
    font-size: 1.75rem;
}

.lg\:text-customsize {
    font-size: 1.75rem;
}

.xl\:text-customsize {
    font-size: 1.75rem;
}

.text-7xl {
    font-size: 5rem;
}

.text-8xl {
    font-size: 6rem;
}

.text-9xl {
    font-size: 7rem;
}

.text-big {
    font-size: 8rem;
}

.h-28 {
    height: 7rem;
}

.min-h-2 {
    min-height: 2px;
}

.no-break {
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (min-width: 1280px) {
    .xl\:big {
        height: 26rem;
    }

    .xl\:text-big {
        font-size: 8rem;
    }
}